* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: 'Trebuchet MS',monospace;
}

.home,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
}

.services {
  background : linear-gradient(5deg, lightblue, beige);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  color: black;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
}

.service-header {
  font-size: 2rem;
  padding-bottom: .5%;
}

.service-p {
  display: flex;
  flex-direction: column; 
  min-width: 50%;
  min-height: 150%;
  justify-content: space-evenly; 
  font-size:1.2rem;
  padding: 2%;
  max-width: 60%;
  height: 50%;
}

.sign-up {
  align-items:flex;
  background : linear-gradient(5deg, lightblue, beige);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 1rem;
}

.textbox{
  margin: 1%;
}

.form-group{
  margin:30px; 
  display:flex;
  font-size: 50px;
  flex-direction: column;
  background:lightblue;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 50%;
  width: 50%;
  position: absolute;
} 

.container {
  margin: 2%;
  padding: 2%;
  display: flex;
  font-size:rem;
  flex-direction:row;
  text-align: center;
}

.input {
  background-color: lightcyan;
  border: 3px solid #FFC03C;
  box-sizing: border-box;
  padding:1%;
  margin:2% ;
}

.interac { 
  max-height: 15%;
  padding-right: 5%;
  max-width: 15%;
}

.helium { 
  max-height: 6%;
  max-width: 6%;
}